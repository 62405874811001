/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import yn from 'yn';

const NODE_ENV: string = process.env.NODE_ENV;
const PUBLIC_URL: string = process.env.GATSBY_APP_PUBLIC_URL || '/';
const APP_NAME: string =
  process.env.GATSBY_APP_MAP_NAME || process.env.GATSBY_APP_NAME || 'UN Biodiversity Lab';
const PRIMARY_COLOR_LIGHT: string = process.env.GATSBY_APP_PRIMARY_COLOR_LIGHT;
const PRIMARY_COLOR_MAIN: string = process.env.GATSBY_APP_PRIMARY_COLOR_MAIN;
const PRIMARY_COLOR_DARK: string = process.env.GATSBY_APP_PRIMARY_COLOR_DARK;
const COMPANY_URL: string = process.env.GATSBY_APP_COMPANY_URL;
const COMPANY_ABOUT_URL: string = process.env.GATSBY_APP_COMPANY_ABOUT_URL;
const COMPANY_SUPPORT_URL: string = process.env.GATSBY_APP_COMPANY_SUPPORT_URL;
const POWERED_BY: string = process.env.GATSBY_APP_POWERED_BY || 'UNEP-WCMC';
const POWERED_BY_URL: string = process.env.GATSBY_APP_POWERED_BY_URL || 'https://www.unep-wcmc.org';
const POWERED_BY_PC_URL: string = process.env.GATSBY_APP_POWERED_BY_PC_URL;
const MAP_BASE_URL: string = process.env.GATSBY_APP_MAP_BASE_URL || '/';
const MAP_API_URL: string = process.env.GATSBY_APP_MAP_API_URL;
const MAP_CDN_URL: string = process.env.GATSBY_APP_MAP_CDN_URL;
const MAP_GRAYSCALE_STYLE: string = process.env.GATSBY_APP_MAP_GRAYSCALE_STYLE;
const MAP_SATELLITE_STYLE: string = process.env.GATSBY_APP_MAP_SATELLITE_STYLE;
const MAP_TERMS_OF_USE_URL: string = process.env.GATSBY_APP_MAP_TERMS_OF_USE_URL;
const MAP_ADMIN_URL: string = process.env.GATSBY_APP_MAP_ADMIN_URL;
const MAP_APP_NAME: string = process.env.GATSBY_APP_MAP_NAME;
const MAP_MAPBOX_TOKEN: string = process.env.GATSBY_APP_MAP_MAPBOX_TOKEN;
const MAP_WEGLOT_API_KEY: string = process.env.GATSBY_APP_MAP_WEGLOT_API_KEY;
const MAP_EXTERNAL_IDP_URL: string = process.env.GATSBY_APP_MAP_EXTERNAL_IDP_URL || '';
const MAP_ENABLE_PUBLIC_ACCESS: boolean = yn(process.env.GATSBY_APP_MAP_ENABLE_PUBLIC_ACCESS, {
  default: false,
});
const MAP_TITILER_URL: string = process.env.GATSBY_APP_MAP_TITILER_URL || '';
const MAP_SHOW_DISCLAIMER: boolean = yn(process.env.GATSBY_APP_MAP_SHOW_DISCLAIMER, {
  default: false,
});
const MAP_ENABLE_ELSA: boolean = yn(process.env.GATSBY_APP_MAP_ENABLE_ELSA, {
  default: false,
});
const ELSA_INPUTS_CONTAINER_NAME: string = process.env.GATSBY_APP_MAP_ELSA_INPUTS_CONTAINER || '';

const MAP_SIDEBAR_WIDTH = 375;
const MAP_SIDEBAR_WIDTH_WIDE = 500;
const SUPER_ADMIN_WORKSPACE: string = '*';

const STAC_PROVIDER_VALUE = 'IO STAC Service';

export {
  NODE_ENV,
  PUBLIC_URL,
  APP_NAME,
  COMPANY_URL,
  COMPANY_ABOUT_URL,
  COMPANY_SUPPORT_URL,
  POWERED_BY,
  POWERED_BY_URL,
  POWERED_BY_PC_URL,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_COLOR_MAIN,
  PRIMARY_COLOR_DARK,
  MAP_BASE_URL,
  MAP_API_URL,
  MAP_CDN_URL,
  MAP_GRAYSCALE_STYLE,
  MAP_SATELLITE_STYLE,
  MAP_SHOW_DISCLAIMER,
  MAP_TERMS_OF_USE_URL,
  MAP_ADMIN_URL,
  MAP_APP_NAME,
  MAP_MAPBOX_TOKEN,
  MAP_WEGLOT_API_KEY,
  MAP_EXTERNAL_IDP_URL,
  MAP_ENABLE_PUBLIC_ACCESS,
  MAP_ENABLE_ELSA,
  ELSA_INPUTS_CONTAINER_NAME,
  MAP_TITILER_URL,
  MAP_SIDEBAR_WIDTH,
  MAP_SIDEBAR_WIDTH_WIDE,
  STAC_PROVIDER_VALUE,
  SUPER_ADMIN_WORKSPACE,
};
