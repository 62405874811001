/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles"; // this will style the admin data grid

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS, esES, frFR, ptBR, ruRU } from "@mui/x-date-pickers/locales";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/pt";
import "dayjs/locale/ru";

import TranslationService from "../../translations/TranslationService";

import { useTranslation, I18nextProvider } from "react-i18next";
import theme, { globalStyleOverrides } from "./theme";
import React from "react";

// https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-add-module-augmentation-for-defaulttheme-typescript
declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

interface IProps {
  children?: React.ReactNode;
  themeConfig?: any;
  weglotApiKey?: string;
}

const localeTextMapping = {
  en: enUS,
  es: esES,
  fr: frFR,
  pt: ptBR,
  ru: ruRU,
};

const globalStyleElement = <GlobalStyles styles={globalStyleOverrides} />;

const WEGLOT_SCRIPT_SRC = "https://cdn.weglot.com/weglot.min.js";
const WEGLOT_SCRIPT_ID = "weglotInitScript";

export const TranslationProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const simpleLocale = localeTextMapping.hasOwnProperty(i18n.language)
    ? i18n.language
    : "en";

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider
        localeText={localeTextMapping[simpleLocale]}
        dateAdapter={AdapterDayjs}
        adapterLocale={simpleLocale}
      >
        {children}
      </LocalizationProvider>
    </I18nextProvider>
  );
};

export const ThemeProvider = (props: IProps) => {
  const { children, themeConfig } = props;
  const [isTranslationServiceInitialised, setIsTranslationServiceInitialised] =
    React.useState(false);

  React.useEffect(() => {
    TranslationService.init().then(() =>
      setIsTranslationServiceInitialised(true)
    );

    if (props.weglotApiKey) {
      if (!document.getElementById(WEGLOT_SCRIPT_ID)) {
        const script = document.createElement("script");

        script.onload = () => TranslationService.initWeglot(props.weglotApiKey);
        script.id = WEGLOT_SCRIPT_ID;
        script.async = true;
        script.src = WEGLOT_SCRIPT_SRC;

        document.head.appendChild(script);
      }
    }
  }, [props.weglotApiKey]);

  return (
    <>
      {/**
       * Inject Emotion before JSS:
       * https://mui.com/material-ui/migration/v5-style-changes/
       */}
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme(themeConfig)}>
          {globalStyleElement}
          <CssBaseline />
          {isTranslationServiceInitialised && (
            <TranslationProvider>{children}</TranslationProvider>
          )}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
