/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import queryStringEncode from "query-string-encode";
import flatten from "flat";
import { Parser } from "json2csv";
import { last } from "lodash";
import dayjs from "dayjs";

/**
 * Serialize filters in the format
 *   filter=type==Continent;Jurisdiction,featured==true
 *   note: each group of values is encoded, including valueSeparator
 */
export const serializeFilters = (filters, filterSep = ",", valueSep = ";") => {
  return Object.keys(filters).reduce((acc, key) => {
    const filterGroup = filters[key];
    const value = Array.isArray(filterGroup)
      ? filterGroup.join(valueSep)
      : filterGroup;
    const encodedFilters = [`${key}==${value}`, acc]
      .filter((e) => !!e)
      .join(filterSep);

    return encodedFilters;
  }, "");
};

/**
 * Url encode
 */
export const encodeQueryToURL = (baseUrl, query) =>
  [baseUrl, queryStringEncode(query)].join("?");

/**
 * Download json file
 * @param data
 * @returns {string}
 */
export const downloadJSONFile = (data) => {
  const encoded = JSON.stringify(data, null, 2);
  const jsonBlob = new Blob([encoded]);
  return URL.createObjectURL(jsonBlob);
};

/**
 * Download csv file
 * @param data
 */
export const downloadCSVFile = (data) => {
  const json2csvParser = new Parser();
  const csv = json2csvParser.parse(flatten(data));
  const csvBlob = new Blob([csv]);
  return URL.createObjectURL(csvBlob);
};

/**
 * Generic way of rendering a date in our apps YYYY-MM-DD
 */
export const getGenericDate = (date: string | Date): string => {
  return dayjs(date).format("YYYY-MM-DD");
};

/**
 * Generic way of rendering a date in our apps YYYY-MM-DD
 */
export const getLongFormDate = (date: string | Date): string => {
  return dayjs(date).format("dddd, Do MMMM YYYY");
};

/**
 * Get initials from user name
 */
export const getInitials = (name: string): string => {
  const emptySpace = " ";
  const [firstName = emptySpace, ...rest] = name.split(emptySpace);
  const lastName = last(rest) || emptySpace;

  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

/**
 * Add target="_blank" to all links in a HTML string
 */
export const addBlankToLinks = (html: string) => {
  if (!html) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const links = doc.querySelectorAll("a");

  links.forEach((link) => {
    link.setAttribute("target", "_blank");
  });

  return doc.body.innerHTML;
};

/**
 * This helper wrapper will call `event.stopPropagation();` and then defer to
 * the original handler. This is useful e.g. when an element with a click
 * handler is nested inside an event without
 *
 * ```jsx
 * <OuterControl
 *   onClick={onOuterClick}
 * >
 *   <Button
 *     onClick={eventStopPropagation(onInnerClick)}
 *   />
 * </OuterControl>
 * ```
 */
export const eventStopPropagation =
  (handler) =>
  (e, ...args) => {
    e.stopPropagation();

    return handler(e, ...args);
  };

/**
 * This helper wrapper will call `event.preventDefault();` and then defer to
 * the original handler. This is useful for stopping default actions like
 * form submissions being triggered.
 *
 * ```jsx
 * <Button
 *   onClick={eventPreventDefault(onButtonPress)}
 * >
 * ```
 */
export const eventPreventDefault =
  (handler) =>
  (e, ...args) => {
    e.preventDefault();

    return handler(e, ...args);
  };

/**
 * Helper for avoiding race conditions where multiple asynchronous operations
 * result in out-of-sequence state mutations.
 *
 * ```js
 * useEffect(
 *   withEffectLock(
 *     (getHasEffectLock) => {
 *       return doSomethingSlowly().then(
 *         (result) => {
 *           if (getHasEffectLock) {
 *             setData(result)
 *           }
 *           // otherwise the effect has already been cleaned up, e.g. due to
 *           // the component being unmounted or effect dependencies changing
 *           // again.
 *         }
 *       )
 *     }
 *   )
 * ),
 * ```
 */
export const withEffectLock =
  (effectFunction: (getHasLock: () => boolean) => void | (() => undefined)) =>
  () => {
    const hasLockWrapper = [true];
    const getHasLock = () => hasLockWrapper[0];

    const cleanup = effectFunction(getHasLock);

    return () => {
      hasLockWrapper[0] = false;

      return cleanup && cleanup();
    };
  };

export const getElsaLayerAzurePath = (
  fileName: string,
  workspace: string,
  isInputLayer: boolean,
  elsa_inputs_container_name: string,
  analysisRunId?: string
) => {
  if (isInputLayer) {
    return `${elsa_inputs_container_name}/workspaces/${workspace}/${fileName}`;
  }
  return `elsa-analyses/workspaces/${workspace}/analysisResults/${analysisRunId}/${fileName}`;
};
