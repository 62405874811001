import isEqual from "lodash/isEqual";
import pick from "lodash/pick";

export type ViewportType = {
  latitude: number;
  longitude: number;
  zoom: number;
  maxZoom: number;
  minZoom: number;
  pitch: number;
  bearing: number;
};

export const DEFAULT_VIEWPORT: ViewportType = {
  minZoom: 2,
  maxZoom: 20,
  zoom: 2,
  latitude: 0,
  longitude: 0,
  pitch: 0,
  bearing: 0,
};

export const RELEVANT_VIEWPORT_ATTRIBUTES = [
  "latitude",
  "longitude",
  "minZoom",
  "maxZoom",
  "zoom",
  "pitch",
  "bearing",
];

export const isValidViewport = ({
  latitude,
  longitude,
  zoom,
  maxZoom,
  minZoom,
  pitch,
  bearing,
}: ViewportType) => {
  return (
    [latitude, longitude, zoom, maxZoom, minZoom, pitch, bearing].every((n) =>
      Number.isFinite(n)
    ) &&
    zoom <= maxZoom &&
    zoom >= minZoom &&
    isValidUrlCoordinateGroup({ latitude, longitude, zoom })
  );
};

export const clampViewportZoom = (viewport: ViewportType) => {
  const { zoom, minZoom, maxZoom } = viewport;

  if (zoom < minZoom) {
    return {
      ...viewport,
      zoom: minZoom,
    };
  } else if (zoom > maxZoom) {
    return {
      ...viewport,
      zoom: minZoom,
    };
  }

  return viewport;
};

export const mergeViewports = (
  newViewportModel: Partial<ViewportType>,
  oldViewport: ViewportType
) => {
  const newViewport = clampViewportZoom(
    pick(
      {
        ...DEFAULT_VIEWPORT,
        ...newViewportModel,
      },
      RELEVANT_VIEWPORT_ATTRIBUTES
    ) as ViewportType
  );

  const hasChanged = !isEqual(
    newViewport,
    pick(oldViewport, RELEVANT_VIEWPORT_ATTRIBUTES)
  );

  return hasChanged ? newViewport : oldViewport;
};

export const viewportAttributeValues = (viewport: ViewportType) =>
  RELEVANT_VIEWPORT_ATTRIBUTES.map((k) => viewport[k] ?? null);

export interface IUrlCoordinates {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const toUrlCoordinateNumber = (value: string): number =>
  Number(parseFloat(value).toFixed(7));

export const isValidCoordinate = (value: number, limit: number): boolean =>
  Number.isFinite(value) && value >= -1 * limit && value <= limit;

export const isValidLatitude = (value: number): boolean =>
  isValidCoordinate(value, 90);

export const isValidLongitude = (value: number): boolean =>
  isValidCoordinate(value, 180);

// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/?size=n_10_n#maxzoom
export const isValidZoom = (value: number): boolean =>
  Number.isFinite(value) && value >= 0 && value <= 24;

export const isValidUrlCoordinateGroup = (value: IUrlCoordinates) => {
  return (
    isValidLatitude(value.latitude) &&
    isValidLongitude(value.longitude) &&
    isValidZoom(value.zoom)
  );
};

export const getMapViewport = (mapLib) => {
  const { lng: longitude, lat: latitude } = mapLib.getCenter();
  const zoom = mapLib.getZoom();
  const minZoom = mapLib.getMinZoom();
  const maxZoom = mapLib.getMaxZoom();
  const pitch = mapLib.getPitch();
  const bearing = mapLib.getBearing();

  return { longitude, latitude, zoom, minZoom, maxZoom, pitch, bearing };
};
