/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import withStyles from "@mui/styles/withStyles";

import {
  DEFAULT_VIEWPORT,
  ViewportType,
  isValidViewport,
  mergeViewports,
  viewportAttributeValues,
  getMapViewport,
} from "./utils";

import ReactMapGL, { AttributionControl, MapRef } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */
// set the worker separately, to avoid transpilation
// per https://docs.mapbox.com/mapbox-gl-js/guides/install/#loading-and-transpiling-the-web-worker-separately
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires*/

const styles = () => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
});

interface SharedMapComponentProps {
  /** MUI classes passed from with styles */
  classes: any;

  /** A function that returns the map instance */
  children?: (c: any) => {};

  /** Custom css class for styling */
  customClass?: string;

  /** Custom HTML to put in the AttributionControl */
  customAttribution?: string;

  /** An object that defines the viewport
   * @see https://uber.github.io/react-map-gl/#/Documentation/api-reference/interactive-map?section=initialization
   */
  viewport?: ViewportType;

  /** A boolean that allows panning */
  dragPan?: boolean;

  /** A boolean that allows rotating */
  dragRotate?: boolean;

  /** A boolean that allows zooming */
  scrollZoom?: boolean;

  /** A boolean that allows zooming */
  touchZoom?: boolean;

  /** A boolean that allows touch rotating */
  touchRotate?: boolean;

  /** A boolean that allows double click zooming */
  doubleClickZoom?: boolean;

  mapboxApiAccessToken: string;
  mapStyle: string;
  interactiveLayerIds?: [];

  /** Callback functions */
  onClick?: (e) => void;
  onLoad?: (e) => void;

  /** A callback run before the Map makes a request for an external URL.
   * The callback can be used to modify the url, set headers, or set the credentials property for cross-origin requests.
   * Expected to return a RequestParameters object with a url property and optionally headers and credentials properties. */
  transformRequest?: (
    url,
    resourceType
  ) => {
    url;
    headers: {};
  };

  /** A function that exposes the viewport */
  onViewportChange?: (viewport: any) => void;
}

const SharedMapComponent = forwardRef(
  (props: SharedMapComponentProps, mapRef: ForwardedRef<MapRef>) => {
    const latestViewportRequest = useRef(null);
    const {
      classes,
      customClass,
      customAttribution,
      children,
      // getCursor,
      dragPan = true,
      dragRotate = true,
      scrollZoom = true,
      touchZoom = true,
      touchRotate = true,
      doubleClickZoom = true,
      ...mapboxProps
    } = props;

    const [viewport, setViewport] = useState({ ...DEFAULT_VIEWPORT });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(
      null
    );

    const updateViewport = (newViewportModel) => {
      const newViewport = mergeViewports(newViewportModel, viewport);

      setViewport(newViewport);

      return newViewport;
    };

    const map = useMemo(() => mapRef?.current?.getMap(), [mapRef?.current]);

    useEffect(() => {
      if (map && isLoaded) {
        const { latitude, longitude, zoom, pitch, bearing } = viewport;

        if (!isValidViewport(viewport)) {
          console.error("Attempted to go to invalid location", viewport);

          return;
        }

        setIsMoving(true);

        map.flyTo({
          center: [longitude, latitude],
          zoom: zoom,
          pitch: pitch,
          bearing: bearing,
        });

        mapboxProps?.onViewportChange?.(viewport);
      }
    }, [map, ...viewportAttributeValues(viewport)]);

    // When viewport props change, merge changes into the local viewport state
    useEffect(() => {
      if (!isLoaded) {
        return;
      }

      latestViewportRequest.current = mapboxProps.viewport;

      // Use setTimeout to defer processing to the next event loop tick,
      // ensuring that rapid sequential viewport updates don't create race conditions.
      // Only the last viewport update in a sequence will be processed, preventing
      // competing updates from interfering with each other.
      const timeoutId = setTimeout(() => {
        if (latestViewportRequest.current === mapboxProps.viewport) {
          const newViewport = updateViewport(mapboxProps.viewport);

          if (viewport !== newViewport) {
            mapboxProps?.onViewportChange?.(newViewport);
          }
        }
      }, 0);

      return () => clearTimeout(timeoutId);
    }, [isLoaded, ...viewportAttributeValues(mapboxProps.viewport)]);

    const onLoad = (e) => {
      mapboxProps?.onLoad?.(e);
      setIsLoaded(true);
    };

    const onMoveEnd = ({ target, viewState = null }) => {
      // Skip updates if controlled movement is happening of if map has not loaded
      if (!isLoaded || isMoving) {
        setIsMoving(false);

        return;
      }

      const newViewportModel = viewState ?? getMapViewport(target);
      const newViewport = updateViewport(newViewportModel);

      if (mapboxProps.viewport !== newViewport) {
        mapboxProps?.onViewportChange?.(newViewport);
      }
    };

    return (
      <div
        ref={(element) => setMapContainer(element)}
        className="marapp-qa-map"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      >
        {mapContainer && (
          <ReactMapGL
            ref={mapRef}
            // CUSTOM PROPS FROM REACT MAPBOX API
            {...mapboxProps}
            maxZoom={viewport.maxZoom}
            minZoom={viewport.minZoom}
            // Fit to container
            style={{
              width: "100%",
              height: "100%",
            }}
            // INTERACTIVITY
            dragPan={dragPan}
            dragRotate={dragRotate}
            scrollZoom={scrollZoom}
            doubleClickZoom={doubleClickZoom}
            // Whether to display the default attribution control
            attributionControl={false}
            // Event Listeners
            onLoad={onLoad}
            onMoveEnd={onMoveEnd}
          >
            {isLoaded &&
              !!mapRef &&
              (typeof children === "function" ? children(map) : children)}
            <div className="mapboxgl-ctrl-parent">
              <AttributionControl
                compact={true}
                customAttribution={customAttribution}
              />
            </div>
          </ReactMapGL>
        )}
      </div>
    );
  }
);

export const Map = withStyles(styles)(SharedMapComponent);

export { DEFAULT_VIEWPORT };
